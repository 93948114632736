import React from 'react'

const notFound = () => {
  return (
    <div>
        <h1>Page Not Found</h1>
    </div>
  )
}

export default notFound